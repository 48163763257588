import {
  BaseQueryApi,
  type BaseQueryFn,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  type FetchArgs,
  type FetchBaseQueryError,
  type FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  incrementActiveRequests,
  decrementActiveRequests
} from '@shared/ui/circular-loader';
import { OMEGA_API_URL } from '../config';

type RootState = {
  session: {
    sessionKey: string;
  };
};

const omegaQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
> = fetchBaseQuery({
  baseUrl: OMEGA_API_URL,
});

type OmegaBaseQueryExtraOptions = {
  auth?: boolean;
  includeBrandId?: boolean;
  showLoader?: boolean;
};

export const baseOmegaQuery = async (
  arg: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: OmegaBaseQueryExtraOptions = { includeBrandId: true }
) => {
  let newArgs = arg;

  if (typeof newArgs !== 'string' && !newArgs.params) {
    newArgs = { ...newArgs, params: {} };
  }

  if (extraOptions?.auth && typeof newArgs !== 'string') {
    const rootState = api.getState() as RootState;
    const sessionKey = rootState?.session?.sessionKey;
    newArgs.params = {
      ...newArgs.params,
      sessionKey,
    };
  }

  if (extraOptions?.includeBrandId !== false && typeof newArgs !== 'string') {
    newArgs.params = {
      ...newArgs.params,
      brandId: 1,
    };
  }

  const shouldShowLoader = extraOptions?.showLoader ?? true;

  try {
    if (shouldShowLoader) api.dispatch(incrementActiveRequests());
    const result = await omegaQuery(newArgs, api, {});
    return result;
  } catch (error) {
    return { error };
  } finally {
    if (shouldShowLoader) api.dispatch(decrementActiveRequests());
  }
};